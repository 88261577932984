// import { gql } from '@apollo/client';
import merge from 'lodash/merge';
import { Sleep } from '../utils/utils';
import { Get_Date, UsingLock } from '../utils/utils';
import DBHandler from '../index-db/db-handler';
import { GLOBAL_VARS, SPORT_LIST, LOCKS } from './globalVars';
import { SME_CON, API_CONTROL } from './api-connection';
import { ConfirmModel, Get_Device_Type } from '../utils/utils';
import SportSelector from '../../components/sport-selector/sport-selector';
import RegisterDevice from '../../components/register_device/register_device';


class Sync {
   sme_con = null;
   apt_control = null;
   db_handler = null;
   SYNC_WATCH_COUNT = 0;

   constructor(authObj, args = {}) {
      // console.log('>>>-------+ Starting  Sync: args', args);
      this.sme_con = new SME_CON(authObj);
      this.apt_control = API_CONTROL({ sme_con: this.sme_con });
      this.db_handler = DBHandler();
   }

   init = () => {
      return new Promise(async (resolve) => {
         try {
            // console.log('>>> SYNC INIT <<<');
            // >>> What for sme_con         
            while (!this.sme_con.ready) {
               if (this.sme_con.client === false) {
                  resolve(false);
                  return
               }
               await Sleep(50);
            }

            this.connection_watch();
            await Sleep(2000);
            while (GLOBAL_VARS.server_connection !== true) {
               console.log('Waiting for server_connection.....')
               resolve(false);
               await Sleep(1000);
            }

            
            const { account_details, club_details, court_details, club_ads, sub_courts, sync_usage } = await this.get_account_data();


            // console.log('>>>>>>>>>>>>> club_ads: ', club_ads)
            // const sport_type = 'Squash';
            // const account_details = await this.apt_control.SME.query.account_details();
            // const club_details = await this.apt_control.SME.query.user_clubs(account_details.user_id);
            // const court_details = await this.apt_control.SME.query.club_courts(club_details[0].club_id, sport_type);
            // console.log('sme_con: ', this.sme_con);
            // console.log('apt_control: ', this.apt_control);
            // console.log('>>> account_details: ', account_details);
            // console.log('>>> club_details: ', club_details);
            // console.log('>>> court_details: ', court_details);
            // console.log('>>> device_list: ', device_list);
            // console.log('>>> user_id: ', account_details.user_id);
            // console.log('>>> username: ', account_details.username);
            // console.log('>>> user_img: ', account_details.user_img); // E.G: https://scoringmadeeasy.com/sport/images/clipart/Animals61.png

            // >>> Update Account         
            const update_account_result = await this.db_handler.update_account(account_details);
            if (!update_account_result) {
               console.error('ERROR - Sync - init - update_account - Unexpected error');
               ConfirmModel({
                  icon: 'error',
                  title: `Device Registration Error`,
                  text: 'Please reset browser cache or contact support. Error: E01',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: true,
                  showCancelButton: false,
                  footer: `<a href="mailto:support@scoringmadeeasy.com">support@scoringmadeeasy.com</a>`
               });
            }

            if (club_details.length > 0) {
               // >>> Pick Club
               let selected_club = 0;
               if (club_details.length > 1) {
                  // TODO Create a Club Picker for Accounts with more that one Club
                  // selected_club = club_picker()
               }

               let club_update = JSON.parse(JSON.stringify(club_details[selected_club]));
               club_update.ad_1 = club_ads.ad1.path;
               club_update.ad_2 = club_ads.ad2.path;
               club_update.ad_3 = club_ads.ad3.path;

               const update_club_result = await this.db_handler.update_club(club_update);
               if (!update_club_result) {
                  console.error('ERROR - Sync - init - update_club - Unexpected error');
                  ConfirmModel({
                     icon: 'error',
                     title: `Device Registration Error`,
                     text: 'Please reset browser cache or contact support. Error: E02',
                     confirmButtonText: 'Ok',
                     allowOutsideClick: true,
                     showCancelButton: false,
                     footer: `<a href="mailto:support@scoringmadeeasy.com">support@scoringmadeeasy.com</a>`
                  });
               }

               const load_settings_result = await this.db_handler.load_settings();
               const deviceSettings = typeof load_settings_result === 'undefined' ? false : load_settings_result;
               if (!deviceSettings) {
                  await this.select_sport();
                  Sleep(100);
                  await ConfirmModel({
                     icon: 'success',
                     title: `Sport Selection Complete`,
                     confirmButtonText: 'Ok',
                     allowOutsideClick: true,
                     showCancelButton: false,
                  });
               }
               const load_sync_result = await this.db_handler.load_sync();
               const deviceRegistered = typeof load_sync_result === 'undefined' ? false : load_sync_result;
               if (!deviceRegistered) {
                  await this.register_device();
                  Sleep(100);
                  ConfirmModel({
                     icon: 'success',
                     title: `Device Registration Complete`,
                     confirmButtonText: 'Ok',
                     allowOutsideClick: true,
                     showCancelButton: false,
                  });

               } else {
                  // >>> Update Linked Court information
                  await this.update_sync_data();
                  // try {
                  //    const selected_court = court_details.filter(i => i.device_id === deviceRegistered.device_id);
                  //    await this.db_handler.update_sync({
                  //       device_id: deviceRegistered.device_id,
                  //       device_name: deviceRegistered.device_name,
                  //       ccfg_id: selected_court.length !== 0 ? selected_court[0].ccfg_id : 0,
                  //       court_name: selected_court.length !== 0 ? selected_court[0].court_name : '',
                  //       court_image: selected_court.length !== 0 ? selected_court[0].court_image : '',
                  //       sub_courts: sub_courts,
                  //       sync_limit: sync_usage,
                  //    });
                  // } catch (error) {
                  //    console.error('ERROR - Sync - init - Update Device/Court - Unexpected error: ', error);
                  // }
               }
            } else {
               console.error('User has no Club');
            }
         } catch (error) {
            console.error('ERROR - Sync - init - Unexpected error: ', error);
            ConfirmModel({
               icon: 'error',
               title: `Device Registration Error`,
               text: 'Please contact support. Error: E05',
               confirmButtonText: 'Ok',
               allowOutsideClick: true,
               showCancelButton: false,
               footer: `<a href="mailto:support@scoringmadeeasy.com">support@scoringmadeeasy.com</a>`
            });
            resolve(false);
         }
         resolve(false);
      });
   }

   update_sync_data = () => {
      return new Promise(async (resolve) => {
         try {
            if (GLOBAL_VARS.server_connection) {
               const { court_details, sub_courts, sync_usage } = await this.get_account_data();
               const load_sync_result = await this.db_handler.load_sync();
               const deviceRegistered = typeof load_sync_result === 'undefined' ? false : load_sync_result;
   
               try {
                  const selected_court = court_details.filter(i => i.device_id === deviceRegistered.device_id);
                  await this.db_handler.update_sync({
                     device_id: deviceRegistered.device_id,
                     device_name: deviceRegistered.device_name,
                     ccfg_id: selected_court.length !== 0 ? selected_court[0].ccfg_id : 0,
                     court_name: selected_court.length !== 0 ? selected_court[0].court_name : '',
                     court_image: selected_court.length !== 0 ? selected_court[0].court_image : '',
                     sub_courts: sub_courts,
                     sync_limit: sync_usage,
                  });
               } catch (error) {
                  console.error('ERROR - Sync - init - Update Device/Court - Unexpected error: ', error);
               }
            }
            resolve(true);
         } catch (error) {
            console.error('ERROR - update_sync_data - root: ', error);
            resolve(false);
         }
      });
   }

   get_account_data = () => {
      return new Promise(async (resolve) => {
         try {
            const sport_type = 'Squash';
            const load_sync_result = await this.db_handler.load_sync();
            const device_id = typeof load_sync_result !== 'undefined' && load_sync_result !== null ? load_sync_result.device_id : 0;
            const account_details = await this.apt_control.SME.query.account_details();
            const club_details = await this.apt_control.SME.query.user_clubs(account_details.user_id);
            const court_details = await this.apt_control.SME.query.club_courts(club_details[0].club_id, sport_type);
            const device_list = await this.apt_control.SME.query.club_devices(account_details.user_id);
            const club_ads = await this.apt_control.SME.query.club_ads(club_details[0].club_id, sport_type);
            const scoring_subs = await this.apt_control.SME.query.user_payments(account_details.user_id, 0);
            const sync_usage = await this.apt_control.SME.query.sync_usage(account_details.user_id, device_id);
            
            // typeof args === 'undefined' || args.sync_usage === true?
            // const scoring_subs = typeof args === 'undefined' || args.sub_courts === true?await this.apt_control.SME.query.user_payments(account_details.user_id, 0):null;
            // const sync_usage = typeof args === 'undefined' || args.sync_usage === true?await this.apt_control.SME.query.sync_usage(account_details.user_id, device_id):null;
            
            device_list.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            const sub_courts = scoring_subs.reduce((a, i) => a + i.py_for_users, 0);
            if (sub_courts === 0) { GLOBAL_VARS.free_app = true; }
            else {
               GLOBAL_VARS.free_app = sync_usage.Result === false ? true : false;
            }
            // console.warn('sync_usage: ', sync_usage.Result);
            resolve({
               sport_type: sport_type,
               account_details: account_details,
               club_details: club_details,
               club_ads: club_ads,
               court_details: court_details,
               device_list: device_list,
               sub_courts: sub_courts,
               sync_usage: sync_usage.Result,
               device_id: device_id,
            });
         } catch (error) {
            console.error('Error get_account_data: Unexpected Error: ', error);
            resolve('Error');
         }
      });
   }

   select_sport = () => {
      return new Promise(async (resolve) => {
         try {
            console.log('>>>>> select_sport');
            console.log('SPORT_LIST: ', SPORT_LIST);
            const sport_result = await this.select_sport_model();
            console.log('sport_result: ', sport_result);
            if (sport_result !== false) {
               await this.db_handler.update_settings({
                  sport: sport_result.name
               });
               resolve(true);
            } else {
               this.select_sport();
            }
         } catch (error) {
            console.error('Error select_sport: Unexpected Error: ', error);
         }
      });
   }

   select_sport_model = () => {
      return new Promise(async (resolve) => {
         const select_sport = async (sport_id) => {
            const sport = SPORT_LIST.filter(i => i.id === sport_id)
            ConfirmModel({
               html: (<SportSelector text={'Confirm Selection'} sport_list={sport} />),
               confirmButtonText: 'Yes',
               showCancelButton: true,
               allowOutsideClick: false,
               css_popup: 'swalPopup_theme_1',
               css_title: 'swalTitle_theme_1',
            }).then((result) => {
               if (result.isConfirmed) {
                  resolve(sport[0]);
               } else {
                  sport_picker();
               }
            });
         }
         const sport_picker = () => {
            ConfirmModel({
               html: (<SportSelector text={'Select Sport'} sport_list={SPORT_LIST} select_callback={select_sport} />),
               showConfirmButton: false,
               showCancelButton: false,
               allowOutsideClick: false,
               width: '100%',
               grow: 'fullscreen',
               css_popup: 'swalPopup_theme_1',
            });
         }
         sport_picker();
      });
   }

   register_device = () => {
      return new Promise(async (resolve) => {
         try {
            const { sport_type, account_details, court_details, device_list, sub_courts, sync_usage } = await this.get_account_data();
            const tokens = await this.sme_con.auth.get_token();
            const filtered_device_list = device_list.filter(i => i.name !== "Unset");
            const result = await this.register_device_model(filtered_device_list, {
               type: Get_Device_Type(),
               sport_type: sport_type,
               user_id: account_details.user_id,
               identifier: tokens.deviceID
            });

            if (result === false) {
               await this.db_handler.update_sync({
                  device_id: 0,
                  ccfg_id: 0,
                  court_name: '',
                  court_image: '',
                  sub_courts: 0,
                  sync_limit: true,
               });
            } else {
               const selected_court = court_details.filter(i => i.device_id === result.device_id);
               const sync_object = {
                  device_id: result.device_id,
                  ccfg_id: selected_court.length > 0 ? selected_court[0].ccfg_id : 0,
                  device_name: result.name,
                  court_name: selected_court.length > 0 ? selected_court[0].court_name : '',
                  court_image: selected_court.length > 0 ? selected_court[0].court_image : '',
                  sub_courts: sub_courts,
                  sync_limit: sync_usage,
               }
               await this.db_handler.update_sync(sync_object);
               await this.apt_control.SME.mutation.update_device(sync_object.device_id, {
                  sport_type: sport_type,
                  identifier: tokens.deviceID,
               });
            }

            resolve(true);
         } catch (error) {
            console.error('Error register_device: Unexpected Error: ', error);
         }
      });
   }

   register_device_model = (device_list, args = {}) => {
      return new Promise(async (resolve) => {
         const select_device = async (device_id) => {
            const device = device_list.filter(i => i.device_id === device_id)
            ConfirmModel({
               title: `Confirm Selection`,
               html: (<RegisterDevice device_list={device} />),
               confirmButtonText: 'Yes',
               showCancelButton: true,
               allowOutsideClick: false,
               css_popup: 'swalPopup_theme_1',
               css_title: 'swalTitle_theme_1',

            }).then((result) => {
               if (result.isConfirmed) {
                  resolve(device[0]);
               } else {
                  device_picker();
               }
            });
         }
         const new_device = (name) => {
            const new_device = [{ name: name, device_id: null }];
            ConfirmModel({
               title: `Register new device?`,
               html: (<RegisterDevice device_list={new_device} />),
               confirmButtonText: 'Yes',
               showCancelButton: true,
               allowOutsideClick: true,
               showLoaderOnConfirm: true,
               css_popup: 'swalPopup_theme_1',
               css_title: 'swalTitle_theme_1',

               preConfirm: async (confirm) => {
                  try {
                     const result = await this.apt_control.SME.mutation.create_device({
                        name: name,
                        sport_type: args.sport_type,
                        type: args.type,
                        user_id: args.user_id,
                        identifier: args.identifier
                     });
                     if (result !== false) {
                        resolve(result);
                        return true;
                     } else {
                        ConfirmModel({
                           icon: 'error',
                           title: `Device Registration Error`,
                           text: 'Please contact support. Error: E03',
                           confirmButtonText: 'Ok',
                           allowOutsideClick: true,
                           showCancelButton: false,
                           footer: `<a href="mailto:support@scoringmadeeasy.com">support@scoringmadeeasy.com</a>`
                        });
                        return false;
                     }
                  } catch (error) {
                     ConfirmModel({
                        icon: 'error',
                        title: `Device Registration Error`,
                        text: 'Please contact support. Error: E04',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: true,
                        showCancelButton: false,
                        footer: `<a href="mailto:support@scoringmadeeasy.com">support@scoringmadeeasy.com</a>`
                     });
                     console.error('Error register_device_model - new_device: Unexpected Error');
                     return false;
                  }
               }
            }).then((result) => {
               if (!result.isConfirmed) {
                  device_picker();
               }
            });
         }
         const device_picker = () => {
            ConfirmModel({
               html: (<RegisterDevice device_list={device_list} show_new_device={true} new_device_callback={new_device} select_callback={select_device} />),
               confirmButtonText: 'Ok',
               cancelButtonText: 'Skip',
               showConfirmButton: false,
               showCancelButton: false,
               allowOutsideClick: false,
               width: '100%',
               grow: 'fullscreen',
               css_popup: 'swalPopup_theme_1',
            });
         }

         device_picker();
      });
   }

   // register_court = (court_details) => {
   //    return new Promise(async (resolve) => {
   //       const select_court = (ccfg_id) => {
   //          const selected_court = court_details.filter(i => i.ccfg_id === ccfg_id);
   //          ConfirmModel({
   //             title: `Confirm Selection`,
   //             html: (<RegisterDevice court_details={selected_court} />),
   //             confirmButtonText: 'Yes',
   //             showCancelButton: true,
   //             allowOutsideClick: true,
   //          }).then((result) => {
   //             if (result.isConfirmed) {
   //                /// Need to Config the next court with this device....
   //                // Planning here
   //                // We know the court we need.
   //                // But we still need a device Nick name
   //                // Need to Sync a device to a court.
   //                // How to handle devices
   //                resolve(selected_court[0]);
   //             } else {
   //                court_picker();
   //             }
   //          });
   //       }
   //       const court_picker = () => {
   //          ConfirmModel({
   //             // icon: 'error',
   //             title: `Sync Court`,
   //             // text: ``,
   //             html: (<RegisterDevice court_details={court_details} selectCallback={select_court} />),
   //             confirmButtonText: 'Ok',
   //             cancelButtonText: 'Skip',
   //             showConfirmButton: false,
   //             showCancelButton: true,
   //             allowOutsideClick: false,
   //          }).then((result) => {
   //             console.log('>>> court_picker result: ', result)
   //             if (result.isDismissed) {
   //                resolve(false)
   //             }
   //          });
   //       }
   //       court_picker();
   //    });
   // }

   

   sync_watch = () => {
      return new Promise(async (resolve) => {
         try {
            const data_check = 15;
            this.SYNC_WATCH_COUNT ++;
            if (this.SYNC_WATCH_COUNT % data_check === 0) { await this.update_sync_data(); }

            if (GLOBAL_VARS.free_app === false) {
               console.debug('%c [BACKEND - SYNC] >>> SYNC WATCH', 'background: #222; color: lightgreen');
               const matchList = await this.db_handler.loadAllMatches();
               const un_synced_matches = matchList.filter(i => i.synced === 0);
               if (un_synced_matches.length === 0) { resolve(true); return }
               const sync = await this.db_handler.load_sync();
               const account = await this.db_handler.load_account();
               const match_const = {
                  user_id: parseInt(account.user_id),
                  device_id: parseInt(sync.device_id),
                  ccfg_id: parseInt(sync.ccfg_id),
               }

               console.debug('%c [BACKEND - SYNC] >>> DEBUG >>> sync_watch >> account', 'background: #222; color: pink', account);
               console.debug('%c [BACKEND - SYNC] >>> DEBUG >>> sync_watch >> sync', 'background: #222; color: pink', sync);
               console.debug('%c [BACKEND - SYNC] >>> DEBUG >>> sync_watch >> matchList', 'background: #222; color: pink', matchList);
               console.debug('%c [BACKEND - SYNC] >>> sync_watch >> match_const', 'background: #222; color: pink', match_const);
               console.debug('%c [BACKEND - SYNC] >>> sync_watch >> un_synced_matches', 'background: #222; color: pink', un_synced_matches);

               for (let a = 0; a < un_synced_matches.length; a++) {
                  console.debug('%c [BACKEND - SYNC] >>> sync_match START', 'background: #222; color: lightgreen');
                  const result = await this.sync_match(match_const, un_synced_matches[a]);
                  console.debug('%c [BACKEND - SYNC] >>> sync_match result', 'background: #222; color: lightgreen', result);
                  if (a + 1 < un_synced_matches.length) {
                     await Sleep(2000);
                  }
               }

               resolve(true);
            } else {
               console.debug('%c [BACKEND - SYNC] >>> SYNC WATCH -- NO SYNC WHILE FREE APP', 'background: #222; color: lightgreen');
               await Sleep(10_000);
               resolve(true);
            }
         } catch (error) {
            console.error('ERROR - Sync - sync_watch - unexpected error: ', error);
            resolve(false);
         }
      });
   }

   sync_match = (match_const = null, match = null) => {
      return new Promise(async (resolve) => {
         try {
            // console.log('>sync_match<');
            console.debug('%c [BACKEND - SYNC] >>> sync_match Start', 'background: #222; color: red', match);
            // console.log('\n-\n\n');

            let p1game = 0;
            let p2game = 0;
            for (let a = 0; a < match.games.length; a++) {
               if (match.games[a].winner === 1) { p1game++; }
               else if (match.games[a].winner === 2) { p2game++; }
            }
            const player_type = match.push_match.tc_id === 0 && match.push_match.t_id === 0 ? 'Quick Match' : 'Member';

            // >>>>>>>>>>>>>>>>>> Sync Match
            const create_match_result = await this.apt_control.SME.mutation.push_match(match.uuid, {
               user_id: match_const.user_id,
               match_code: match.match_code,
               player1: match.settings.player_a,
               player2: match.settings.player_b,
               player3: typeof match.settings.player_c !== 'undefined' ? match.settings.player_c : '',
               player4: typeof match.settings.player_d !== 'undefined' ? match.settings.player_d : '',
               match_type: match.push_match.match_type !== '' ? match.push_match.match_type : 'QM',
               completed: match.winner === '' ? '0' : '1',
               // player_country
               game_points: typeof match.settings.points_per_game !== 'undefined' ? parseInt(match.settings.points_per_game) : 0,
               best_of: typeof match.settings.games_per_match !== 'undefined' ? parseInt(match.settings.games_per_match) : 0,
               win_by: match.settings.win_by + '',
               p1game: p1game,
               p2game: p2game,
               date: Get_Date(),
               timelimit: typeof match.settings.continuous_time !== 'undefined' ? parseInt(match.settings.continuous_time) : 0,
               p1code: parseInt(match.players.teams[0].players[0].id),
               p2code: parseInt(match.players.teams[1].players[0].id),
               p3code: typeof match.players.teams[0].players[1] !== 'undefined' ? parseInt(match.players.teams[0].players[1].id) : 0,
               p4code: typeof match.players.teams[1].players[1] !== 'undefined' ? parseInt(match.players.teams[1].players[1].id) : 0,
               deviceid: match_const.device_id,
               groupid: match.push_match.t_id,
               p1_Type: player_type,
               p2_Type: player_type,
               p3_Type: player_type,
               p4_Type: player_type,
               sport_type: match.sport,
               // match_graded
               // win_by_pmt
               // win_by_pmt_dec
               match_option: typeof match.settings.match_options !== 'undefined' ? parseInt(match.settings.match_options) + 1 : 2,
               // deciding_match
               // point_b_points
               // tiebreak_points
               // dec_m_winby
               // score_opt
               // match_max_points
               // match_select_sport
               uuid: match.uuid,
               // match_defaulted_player
               // source
               ccfg_id: match_const.ccfg_id,
               p1_color: match.settings.player_a_color,
               p2_color: match.settings.player_b_color,
               p3_color: typeof match.settings.player_c_color !== 'undefined' ? match.settings.player_c_color : '',
               p4_color: typeof match.settings.player_d_color !== 'undefined' ? match.settings.player_d_color : '',
            });
            console.debug('%c [BACKEND - SYNC] >>> push_match result', 'background: #222; color: red', create_match_result);

            if (create_match_result !== false) {
               match.mid = parseInt(create_match_result.mid);
               this.update_match(match.id, { mid: parseInt(create_match_result.mid) }, 'Match-MID');
               this.push_match_sync(match, create_match_result.mid, match_const.device_id);

               // >>>>>>>>>>>>>>>>>> Sync Match Relation
               const games_to_sync = [];
               for (let a = 0; a < match.games.length; a++) {
                  let serve_pos = 0;
                  try {
                     if (match.games[a].logs.length > 0) {
                        const last_log = match.games[a].logs[match.games[a].logs.length-1];
                        serve_pos = last_log.server_side;
                        if (last_log.content === 'HO' && serve_pos === 1) {
                           serve_pos = 2;
                        } else if (last_log.content === 'HO' && serve_pos === 2) {
                           serve_pos = 1;
                        }
                     }
                  } catch (error) {
                     console.error('ERROR - sync_match - Match_Relation serve_pos: ', error);
                  }

                  const winner_name = match.games[a].winner === 1 ? match.settings.player_a : match.games[a].winner === 2 ? match.settings.player_b : '';
                  games_to_sync.push({
                     mrid: match.games[a].mrid,
                     mid: match.mid,
                     p1l: match.games[a].team_1_score,
                     // p1r
                     p2l: match.games[a].team_2_score,
                     // p2r
                     points: match.settings.points_per_game + '',
                     winby: winner_name,
                     start_time: match.games[a].start_time,
                     end_time: match.games[a].end_time !== null ? match.games[a].end_time : Get_Date(),
                     // breaktime
                     // break_reason
                     game_status: match.match_state,
                     serve_pos: serve_pos,
                  });
               }

               const sync_match_rel_result = await this.apt_control.SME.mutation.sync_match_relation(match.mid, games_to_sync);
               if (sync_match_rel_result !== false) {
                  const update_obj = { games: [] }
                  for (let a = 0; a < match.games.length; a++) {
                     if (typeof sync_match_rel_result[a] !== 'undefined') {
                        match.games[a].mrid = parseInt(sync_match_rel_result[a].mrid);
                        update_obj.games[a] = { mrid: parseInt(sync_match_rel_result[a].mrid) }
                     }
                  }

                  this.update_match(match.id, update_obj, 'Games-MRID');
               }
               console.debug('%c [BACKEND - SYNC] >>> sync_match_relation result', 'background: #222; color: red', sync_match_rel_result);

               // >>>>>>>>>>>>>>>>>> Sync Match Logs
               const update_obj = { games: [] }
               for (let a = 0; a < match.games.length; a++) {
                  update_obj.games[a] = {
                     synced: 0,
                     logs: [],
                  }
                  const winner_name = match.games[a].winner === 1 ? match.settings.player_a : match.games[a].winner === 2 ? match.settings.player_b : '';
                  const logs_to_sync = [];
                  for (let b = 0; b < match.games[a].logs.length; b++) {
                     const action = this.convert_log_action(match.games[a].logs[b]);
                     logs_to_sync.push({
                        ml_id: parseInt(match.games[a].logs[b].ml_id),
                        mrid: match.games[a].mrid,
                        // breakt: 
                        ml_action: action,
                        ml_content: match.games[a].logs[b].content,
                        ml_logsts: 1,
                        ml_logtime: match.games[a].logs[b].timestamp,
                        serv_side: match.games[a].logs[b].server_side + '',
                        server: match.games[a].logs[b].server_player,
                        // timestamp: match.games[a].logs[b].timestamp,
                     });
                  }
                  const sync_log_result = await this.apt_control.SME.mutation.sync_match_logs(match.games[a].mrid, logs_to_sync);
                  if (sync_log_result !== false) {
                     for (let b = 0; b < match.games[a].logs.length; b++) {
                        if (typeof sync_log_result[b] !== 'undefined') {
                           match.games[a].logs[b].synced = 1;
                           match.games[a].logs[b].ml_id = sync_log_result[b].ml_id;
                           update_obj.games[a].logs[b] = { synced: 1, ml_id: parseInt(sync_log_result[b].ml_id) }
                        }
                     }
                     this.update_match(match.id, update_obj, 'Logs-MLID');
                  }
                  console.debug('%c [BACKEND - SYNC] >>> sync_log_result result', 'background: #222; color: red', sync_log_result);

                  // if (winner_name !== '') {
                  if (match.games[a].state === 'complete') {
                     match.games[a].synced = 1;
                     update_obj.games[a].synced = 1;
                     this.update_match(match.id, update_obj, 'Game-Complete');
                  }
               }

               if (match.winner !== '') {
                  const match_validation = await this.validate_synced_match(match);
                  console.debug('%c [BACKEND - SYNC] >>> match_validation result', 'background: #222; color: red', match_validation);
                  console.log('match_validation: ', match_validation);
                  if (match_validation) {
                     if (match.push_match.t_id !== 0) {
                        this.push_match_sync_finish(match);
                        const match_complete_result = await this.apt_control.SME.mutation.run_match_complete(match.uuid);
                        // console.warn('match_complete_result: ', match_complete_result.Result);
                        if (match_complete_result.Result) {
                           match.synced = 1;
                           this.update_match(match.id, { synced: 1 }, 'Match-Synced');
                        } else if (match.sync_error > 50) {
                           console.error('ERROR - run_match_complete - retries exhausted, giving up');
                           match.synced = 1;
                           this.update_match(match.id, { synced: 1 }, 'Match-Synced');
                        } else {
                           console.error('ERROR - run_match_complete failed, Retrying');
                           match.sync_error += 1;
                           this.update_match(match.id, { sync_error: match.sync_error }, 'Match-Sync-Error');
                        }
                     } else {
                        match.synced = 1;
                        this.update_match(match.id, { synced: 1 }, 'Match-Synced');
                     }
                  }
               } else if (match.match_state === 'match_cleared') {
                  console.debug('%c [BACKEND - SYNC] >>> Match Cleared', 'background: #222; color: red');
                  match.synced = 1;
                  this.update_match(match.id, { synced: 1 }, 'Match-Synced');
                  this.push_match_sync_finish(match, 2);
               } else {
                  const update_device_match = await this.apt_control.SME.mutation.update_device(match_const.device_id, {
                     current_match: match.games[match.games.length - 1].mrid,
                     access_date: Get_Date(),
                  });
                  console.debug('%c [BACKEND - SYNC] >>> update_device_match result', 'background: #222; color: red', update_device_match);
               }

               await this.db_handler.update_sync({ device_id: match_const.device_id });
            }
            resolve(true);
         } catch (error) {
            console.error('ERROR - Sync - sync_match - unexpected error: ', error);
            resolve(false);
         }
      });
   }

   validate_synced_match = (match) => {
      return new Promise(async (resolve) => {
         const full_match = await this.apt_control.SME.query.match_full(match.mid);
         // console.log('>>>>>>>>> full_match: ', full_match);
         // console.log('>>>>>>>>> match: ', match);
         let synced = true;
         for (let a = 0; a < match.games.length; a++) {
            if (match.games[a].synced === 0) {
               synced = false;
               // console.log('Games X');
               // console.log('match.games: ', match.games);
            } else {
               for (let b = 0; b < match.games[a].logs.length; b++) {
                  if (match.games[a].logs[b].synced === 0) {
                     synced = false;
                     // console.log('Game Logs X');
                     match.games[a].synced = 1;
                  }
               }
            }
         }
         // console.log('validate_synced_match - synced: ', synced);
         if (synced) {
            let match_error = false;
            let p1game = 0;
            let p2game = 0;
            for (let a = 0; a < match.games.length; a++) {
               if (match.games[a].winner === 1) { p1game++; }
               else if (match.games[a].winner === 2) { p2game++; }
            }
            if (full_match.match.win_by !== match.settings.win_by + '') { match_error = true; console.log('A') }
            if (full_match.match.completed !== "1") { match_error = true; console.log('B') }
            if (full_match.match.p1game !== p1game) { match_error = true; console.log('C') }
            if (full_match.match.p2game !== p2game) { match_error = true; console.log('D') }
            console.debug('DEBUG >>> validate_synced_match >> match_error: ', match_error);
            // TODO: Add to this if match sync invalid 
            if (match_error === false) {
               resolve(true);
            }
         }
         resolve(false);
      });
   }

   convert_log_action = (log) => {
      let action = '';
      // console.debug('DEBUG >> convert_log_action log: ', log);
      if (log.action === 'P') {
         if (log.score_side === 1 && log.content === 'HO') { action = 'p1ho'; }
         else if (log.score_side === 1 && log.server_inner_side === 1) { action = 'p1l'; }
         else if (log.score_side === 1 && log.server_inner_side === 2) { action = 'p1r'; }
         else if (log.score_side === 2 && log.content === 'HO') { action = 'p2ho'; }
         else if (log.score_side === 2 && log.server_inner_side === 1) { action = 'p2l'; }
         else if (log.score_side === 2 && log.server_inner_side === 2) { action = 'p2r'; }
      } else {
         action = log.action;
      }
      // console.debug('DEBUG >> convert_log_action action: ', action);
      return action;
   }


   connection_watch = async () => {
      try {
         console.debug('>>> connection_watch <<<');
         this.apt_control.SME.subscription.keep_alive(0, async (result) => {
            if (result.sts === false) {
               GLOBAL_VARS.server_connection = false;
               console.error('ERROR - connection_watch: Connection Lost');
               await Sleep(5000);
               this.connection_watch();
               return
            }

            const lastDate = new Date(parseInt(result.data.last_update));
            const currentDate = new Date();
            GLOBAL_VARS.keep_alive_seconds = Math.floor((currentDate - lastDate) / 60);
            GLOBAL_VARS.server_connection = true;
         });
      } catch (error) {
         console.error('ERROR - connection_watch: ', error);
         GLOBAL_VARS.server_connection = false;
         Sleep(10_000);
         this.connection_watch();
      }
   }

   update_match = (match_id, update, tag = '') => {
      return new Promise(async (resolve) => {
         try {

            // console.log('-- update_match')
            UsingLock(LOCKS.update_match_lock, async () => { 
               // console.log('-- update_match - lock')
               const match = await this.db_handler.loadMatch(match_id);
               const merged_match = merge(match, update);
               const result = await this.db_handler.updateMatch(match_id, merged_match, 'Sync-' + tag);
               resolve(result);
               // console.log('-- update_match - done')
            });
            // console.log('-- update_match - XX')

            
            

            // while (LOCKS.lock.has(LOCKS.update_match_lock)) {
            //    await Sleep(250);
            //    /// TODO -- DEBUG +----------------------------------------
            //    console.error('\n\nupdate_match - Posable Race condition detected: A1\n\n')
            //    console.log('Lock---', LOCKS.lock.has(LOCKS.update_match_lock))
            //    /// TODO -- DEBUG +----------------------------------------
            // }
            // const match = await this.db_handler.loadMatch(match_id);
            // /// TODO -- DEBUG +----------------------------------------
            // const test_a = match.games[0].team_1_score + '-' + match.games[0].team_2_score;
            // /// TODO -- DEBUG +----------------------------------------
            // const merged_match = merge(match, update);
            // /// TODO -- DEBUG +----------------------------------------
            // const test_b = merged_match.games[0].team_1_score + '-' + merged_match.games[0].team_2_score;
            // /// TODO -- DEBUG +----------------------------------------
            // // console.log('---Sync Update Match--- ', tag);

            // /// TODO -- DEBUG +----------------------------------------
            // if (test_a !== test_b) { // Doesnt appear to be this
            //    console.error('\n\nupdate_match - Posable Race condition detected: B2 \n\n')
            //    console.error('__ERRR__');
            //    console.log('test_a: ', test_a);
            //    console.log('test_b: ', test_b);
            //    console.log('---Sync Update Match--- ', tag);
            // }
            // /// TODO -- DEBUG +----------------------------------------

            // const result = await this.db_handler.updateMatch(match_id, merged_match, 'Sync-' + tag);
            // resolve(result);
         } catch (error) {
            resolve(false);
         }
      });
   }

   push_match_sync = (match, mid, device_id) => {
      try {
         if (match.push_match.t_id !== 0) {
            this.apt_control.SME.mutation.update_push_match(match.push_match.load_mid, {
               mid: parseInt(mid),
               device_id: device_id + '',
               loaded_status: 3,
            }).then((result) => {
               console.debug('%c [BACKEND - SYNC] >>> push_match_sync result', 'background: white; color: purple', result);
            });
         }
      } catch (error) {
         console.error('ERROR - Sync - push_match_sync - unexpected error: ', error);
      }
   }

   push_match_sync_finish = (match, state = 4) => {
      try {
         if (match.push_match.t_id !== 0) {
            this.apt_control.SME.mutation.update_push_match(match.push_match.load_mid, {
               mid: null,
               device_id: null,
               loaded_status: state,
            }).then((result) => {
               console.debug('%c [BACKEND - SYNC] >>> push_match_sync_finish result', 'background: white; color: purple', result);
            });
         }
      } catch (error) {
         console.error('ERROR - Sync - push_match_sync_finish - unexpected error: ', error);
      }
   }

}


export default Sync;